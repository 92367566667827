import React, {Component} from 'react';
import { Loading } from "./LoadingComponent";
import {Card, CardBody, CardTitle, Row, Col, Label, Button, Container, Input} from 'reactstrap';
import { Control, Errors, LocalForm} from 'react-redux-form';
import { FadeTransform } from 'react-animation-components';
import {Redirect,  withRouter} from 'react-router-dom';
import './ProductComponent.css';

function setCookie(name, value, days){
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
class ProductComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            disclamierError:null,
            toAdvice: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setDisclamierError = this.setDisclamierError.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    setDisclamierError(error){
        this.setState({
            disclamierError: error
        });
    }

    goToAdvice = () => {
        this.setState({
            toAdvice: true
        })
    }


    handleChange(model,value){
       console.log("changes made"  + value);
    }

    getValue(label){
        return this.props.language.locale=="en"?label.english:label.dutch;
    }



    handleSubmit(values){
        if(!values.acceptDisclamier){
            this.setDisclamierError(this.props.language.disclamierError);
            return;
        } else {
            setCookie("disclaimer_cookie", "true", 180)
        }
        const criteria = this.props.productOptions.productOptions;
        if(criteria.typeOfPick!=null) criteria.typeOfPick.id=values.typeOfPick;
        if(criteria.apply1Mcp!=null) criteria.apply1Mcp.id=values.apply1Mcp;
        if(criteria.normalOrControlledAir!=null) criteria.normalOrControlledAir.id=values.normalOrControlledAir;
        if(criteria.climateZone!=null) criteria.climateZone.id = values.climateZone;
        this.props.submitProductCriteria(criteria,this.props.email,this.props.language.locale);
        this.goToAdvice();
        this.forceUpdate();
    }

    sortTypeOfPick(a, b) {
        if (a.value.startsWith('V') || a.value.startsWith('E')) {
            return -1;
        }
        if (a.value.startsWith('1') && (b.value.startsWith('2') || b.value.startsWith('L'))) {
            return -1;
        }
        if (a.value.startsWith('2') && b.value.startsWith('L')) {
            return -1;
        }
        return 1;
    }

    render(){
        const criteria = this.props.productOptions;
        
        if (this.state.toAdvice) {
            return <Redirect to="/advice/" />
        }
        var disclaimerCookie = getCookie("disclaimer_cookie");

        if(criteria.isLoading){
            return(
                <Loading/>
            );
        }else if(criteria.errMess){
            if(criteria.errMess=="Error 403: Forbidden"){
                this.props.setEmail(null);
                this.props.setAuthentication(false,this.props.language.sessionExpiredMessage);
                this.props.logout();
                return(
                    <div className="alert alert-danger">
                        <strong>{this.props.language.sessionExpiredMessage}</strong>
                    </div>
                );
            }
            return(
                <div className="alert alert-danger">
                    <strong>{this.props.language.technicalError}</strong>
                </div>
            );
        }else {
            return(
                <Container className="pt-5">
                        <Card>
                            <CardBody>
                                <CardTitle className="d-flex justify-content-center">{this.props.language.productTitle}</CardTitle>
                                <hr/>
                                <Row>
                                    <Col className="col-12">
                                        <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                                            <Row className="form-group" >
                                                {criteria.productOptions.product!==null && (<Col md={6}  className="col-12 pt-3">
                                                    <Label htmlFor="product">{this.props.language.product}</Label>
                                                   <Control.select model=".product" id="product" name="product"
                                                                    defaultValue={criteria.productOptions.product.id}
                                                                   changeAction={(model,value)=>{
                                                                       if(value!==null && criteria.productOptions.product.id!==value){
                                                                           criteria.productOptions.product.id=parseInt(value);
                                                                           criteria.productOptions.product.options.forEach(function (item, index) {
                                                                               if (parseInt(item.id) == parseInt(value)) {
                                                                                   criteria.productOptions.product.label.english = item.label.english;
                                                                               }
                                                                           });
                                                                           this.props.fetchProductCriteria(criteria.productOptions,'variety');
                                                                       }
                                                                   }}
                                                                    className="form-control" >
                                                        {criteria.productOptions.product.options.sort((a, b) => a.value > b.value ? 1 : -1).map(item => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.product.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>
                                                )}
                                                {(criteria.productOptions.variety!==null && criteria.productOptions.variety.options.length>0) && (<Col md={(criteria.productOptions.mutant!==null && criteria.productOptions.mutant.options.length>0)?3:6}  className="col-12 pt-3">
                                                    <Label htmlFor="variety">{this.props.language.variety}</Label>
                                                    <Control.select model=".variety" id="variety" name="variety"
                                                                    defaultValue={criteria.productOptions.variety.id}
                                                                    changeAction={(model,value)=>{
                                                                        if(value!==null  && criteria.productOptions.variety.id!==value){
                                                                            const selectedVariety = criteria.productOptions.variety.options.filter((item) => {return parseInt(item.id) === parseInt(value)})[0];
                                                                            criteria.productOptions.variety.id = parseInt(value);
                                                                            criteria.productOptions.variety.label = selectedVariety.label;
                                                                            criteria.productOptions.variety.value = selectedVariety.value;
                                                                            this.props.fetchProductCriteria(criteria.productOptions,'mutant');
                                                                        }
                                                                    }}
                                                                    className="form-control">
                                                        {criteria.productOptions.variety.options.sort((a, b) => a.value > b.value ? 1 : -1).map(item => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.variety.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>)}
                                                {(criteria.productOptions.variety !==null && criteria.productOptions.variety.options.length===0) && (<Col md={6} className="text-danger">
                                                    <p>No product criteria for this product type</p>
                                                    </Col>)}
                                                {(criteria.productOptions.mutant!==null && criteria.productOptions.mutant.options.length>0
                                                    && criteria.productOptions.variety.options.length>0) && (<Col md={3}  className="col-12 pt-3">
                                                    <Label htmlFor="mutant">{this.props.language.mutant}</Label>
                                                    <Control.select model=".mutant" id="mutant" name="mutant"
                                                                    defaultValue={criteria.productOptions.mutant.id}
                                                                    changeAction={(model,value)=>{
                                                                        if(value!==null && criteria.productOptions.mutant.id!==value){
                                                                            criteria.productOptions.mutant.id=parseInt(value);
                                                                            this.props.fetchProductCriteria(criteria.productOptions,'product-criteria');
                                                                        }
                                                                    }}
                                                                    className="form-control">
                                                        {criteria.productOptions.mutant.options.map(item => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.mutant.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>)}
                                                {(criteria.productOptions.typeOfPick!==null && criteria.productOptions.variety.options.length>0) && (<Col md={6}  className="col-12 pt-3">
                                                    <Label htmlFor="typeOfPick">{criteria.productOptions.product.label.english == 'Apple' && this.props.language.typeOfPickApple}{criteria.productOptions.product.label.english != 'Apple' && this.props.language.typeOfPick}</Label>
                                                    <Control.select model=".typeOfPick" id="typeOfPick" name="typeOfPick"
                                                                    defaultValue={criteria.productOptions.typeOfPick.id}
                                                                    className="form-control">
                                                        {criteria.productOptions.typeOfPick.options.sort(this.sortTypeOfPick).map(item => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.typeOfPick.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>)}
                                                {(criteria.productOptions.normalOrControlledAir!==null && criteria.productOptions.variety.options.length>0) && (<Col md={6}  className="col-12 pt-3">
                                                    <Label htmlFor="normalOrControlledAir">{this.props.language.normalOrControlledAir}</Label>
                                                    <Control.select model=".normalOrControlledAir" id="normalOrControlledAir" name="normalOrControlledAir"
                                                                    defaultValue={2}
                                                                    className="form-control">
                                                        {criteria.productOptions.normalOrControlledAir.options.sort((a, b) => a.value > b.value ? 1 : -1).map((item, index) => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.normalOrControlledAir.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>)}
                                                {(criteria.productOptions.apply1Mcp!==null && criteria.productOptions.variety.options.length>0) && (<Col md={6}  className="col-12 pt-3">
                                                    <Label htmlFor="apply1Mcp">{this.props.language.apply1Mcp}</Label>
                                                    <Control.select model=".apply1Mcp" id="apply1Mcp" name="apply1Mcp"
                                                                    defaultValue={criteria.productOptions.apply1Mcp.id}
                                                                    className="form-control">
                                                        {criteria.productOptions.apply1Mcp.options.map(item => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.apply1Mcp.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>)}
                                                {(criteria.productOptions.climateZone!==null && criteria.productOptions.variety.options.length>0) && (<Col md={6}  className="col-12 pt-3">
                                                    <Label htmlFor="climateZone">{this.props.language.climateZone}</Label>
                                                    <Control.select model=".climateZone" id="climateZone" name="climateZone"
                                                                    defaultValue={criteria.productOptions.climateZone.id}
                                                                    className="form-control">
                                                        {criteria.productOptions.climateZone.options.map(item => {
                                                            return <option key={item.order} value={item.id}
                                                                           selected={criteria.productOptions.climateZone.id===item.id}>{this.getValue(item.label)}</option>;
                                                        })}
                                                    </Control.select>
                                                </Col>)}
                                               <Col md={6}  className="col-12" className="d-flex justify-content-start">
                                                   <div className="d-flex flex-column bd-highlight mb-3">
                                                       <Label className="pt-3" check>
                                                           <Control.checkbox model=".acceptDisclamier" id="acceptDisclamier" name="acceptDisclamier"
                                                                             defaultValue={disclaimerCookie != null} 
                                                           />
                                                           {" " + this.props.language.termsAndConditions + " "}
                                                           <a href={this.props.language.termsAndConditionsURL} target="_blank"
                                                              className="normal">{this.props.language.termsAndConditionsLink}</a>
                                                       </Label>
                                                       {this.state.disclamierError!=null && <div className="text-danger">
                                                           <strong>{this.state.disclamierError}</strong>
                                                       </div>}
                                                   </div>

                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col>
                                                    <Button type="submit" color="primary" className="form-control background-green"
                                                            disabled={criteria.productOptions.product.id==null |
                                                            (criteria.productOptions.variety!=null && criteria.productOptions.variety.id==null)}>
                                                        {this.props.language.receiveAdviseVia}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </LocalForm>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                </Container>
            );
        }
    }
}

export default withRouter(ProductComponent);