import React, {Component} from 'react';
import { Loading } from "./LoadingComponent";
import { ErrorComponent } from "./ErrorComponent";
import {Card, CardBody, CardTitle, CardSubtitle, Input, Row,
    Col, Label, Container, Alert} from 'reactstrap';
import { FadeTransform } from 'react-animation-components';

class AdviceComponent extends Component{
    constructor(props){
        super(props);
        this.getValue = this.getValue.bind(this);
    }

    getValue(label){
        return this.props.language.locale=="en"?label.english:label.dutch;
    }

    render(){
        const storageAdvice = this.props.storageAdvice;
        if(storageAdvice.isLoading){
            return(
                <Loading/>
            );
        }else if(storageAdvice.errMess){
            if(storageAdvice.errMess=="Error 403: Forbidden"){
                this.props.setEmail(null);
                this.props.setAuthentication(false,this.props.language.sessionExpiredMessage);
                this.props.logout();
                return(
                    <div className="alert alert-danger">
                        <strong>{this.props.language.sessionExpiredMessage}</strong>
                    </div>
                );
            }
            return(
                <div className="alert alert-danger">
                    <strong>{this.props.language.technicalError}</strong>
                </div>
            );
        }else {
            const advice = storageAdvice.storageAdvice;
            storageAdvice.isLoading = false;
            if(!advice){
                console.log("Data not found",advice);
                return  ( <ErrorComponent url="/product"
                                          setFunction={this.props.setAdviseLoading}
                                          error={this.props.language.adviseNotFound}
                />);
            }

            return(
                <Container className="pt-5">
                    <FadeTransform in
                                   transfromProps={{
                                       exitTransform: 'scale(0.5) translate()-50%'
                                   }}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col className="col-12 mt-3">
                                        <CardTitle className="d-flex">{this.props.language.adviseTitle}</CardTitle>
                                        {!advice.product &&
                                            <Alert color="danger center-text">{this.props.language.adviseNotFound}</Alert>
                                        }
                                        {/*{(advice.product && this.getValue(advice.product.variety.label) === 'Fuji') &&*/}
                                        {/*    <Alert color="danger center-text">{this.props.language.watercoreWarning}</Alert>*/}
                                        {/*}*/}
                                        <hr/>
                                        {(advice.roomTempLoading || advice.roomTempAfterLoading) &&
                                        <Row className="mt-3 mb-1">
                                            <Col className="col-12 col-md-4"></Col>
                                            <Col className="col-12 col-md-8">
                                                <CardSubtitle
                                                    className="group-header">{this.props.language.temperature}</CardSubtitle>
                                            </Col>
                                        </Row>
                                        }
                                        {advice.roomTempLoading && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseTemperatureDuringImpact">{this.props.language.adviseTemperatureDuringImpact}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseTemperatureDuringImpact" name="adviseTemperatureDuringImpact" className="form-control grey-field">{this.getValue(advice.roomTempLoading.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.roomTempAfterLoading && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseTemperatureAfterImpact">{this.props.language.adviseTemperatureAfterImpact}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseTemperatureAfterImpact" name="adviseTemperatureAfterImpact" className="form-control grey-field">{this.getValue(advice.roomTempAfterLoading.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.mcp && !advice.mcp.label.english.toLowerCase().startsWith("negative") && <Row className="mt-3 mb-1">
                                            <Col className="col-12 col-md-4"></Col>
                                            <Col className="col-12 col-md-8">
                                                <CardSubtitle className="group-header">{this.props.language.adviseMCPTitle}</CardSubtitle>
                                            </Col>
                                        </Row>}
                                        {advice.mcp && !advice.mcp.label.english.toLowerCase().startsWith("negative") && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseMCP">{this.props.language.apply1Mcp}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseMCP" name="adviseMCP" className="form-control grey-field">{this.getValue(advice.mcp.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.mcp && advice.mcp.label.english.toLowerCase().startsWith("negative") && <Row className="p-2">
                                            <Col className="col-12 col-md-12">
                                                <Label>{this.getValue(advice.mcp.label)}</Label>
                                            </Col>
                                        </Row>}
                                        {advice.co2DuringRoomLoad &&
                                            <Row className="mt-3 mb-1">
                                                <Col className="col-12 col-md-4"></Col>
                                                <Col className="col-12 col-md-8">
                                                    <CardSubtitle className="group-header">{this.props.language.carbonDioxideBeheer}</CardSubtitle>
                                                </Col>
                                            </Row>
                                        }
                                        {advice.co2DuringRoomLoad && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseDuringImpact">{this.props.language.adviseDuringImpact}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseDuringImpact" name="adviseDuringImpact" className="form-control grey-field">{this.getValue(advice.co2DuringRoomLoad.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.co2AfterRoomLoad && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseAfterImpact">{this.props.language.adviseAfterImpact}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseAfterImpact" name="adviseAfterImpact" className="form-control grey-field">{this.getValue(advice.co2AfterRoomLoad.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.co2RestOfStoragePeriod && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseStoragePeriodReminderCA">
                                                    {advice.product.normalOrControlledAir.value=="CA"?
                                                        this.props.language.adviseStoragePeriodReminderCA:this.props.language.adviseStoragePeriodReminderRA}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseStoragePeriodReminderCA" name="adviseStoragePeriodReminderCA" className="form-control grey-field">{this.getValue(advice.co2RestOfStoragePeriod.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.co2RestOfStoragePeriodDca && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseAfterImpact">{this.props.language.adviseStoragePeriodReminderDCA}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseStoragePeriodReminderDCA" name="adviseStoragePeriodReminderDCA" className="form-control grey-field">{this.getValue(advice.co2RestOfStoragePeriodDca.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.o2DuringRoomLoadAfter && <Row className="mt-3 mb-1">
                                            <Col className="col-12 col-md-4"></Col>
                                            <Col className="col-12 col-md-8">
                                                    <CardSubtitle className="group-header">{this.props.language.oxygenManagement}</CardSubtitle>
                                                </Col>
                                            </Row>
                                        }
                                        {advice.o2DuringRoomLoadAfter && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseAfterLoading">{this.props.language.adviseDuringLoading}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseDuringLoading" name="adviseDuringLoading" className="form-control grey-field">{this.getValue(advice.o2DuringRoomLoadAfter.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.o2AfterRoomLoading && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseAfterLoading">{this.props.language.adviseAfterLoading}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseAfterLoading" name="adviseAfterLoading" className="form-control grey-field">{this.getValue(advice.o2AfterRoomLoading.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.o2FastDeclineTo && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseForFastDeclineTo">{this.props.language.adviseForFastDeclineTo}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseForFastDeclineTo" name="adviseForFastDeclineTo" className="form-control grey-field">{this.getValue(advice.o2FastDeclineTo.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.o2SubsequentlySlowDeclineTo && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseForSubsequentSlowDecline">{this.props.language.adviseForSubsequentSlowDecline}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseForSubsequentSlowDecline" name="adviseForSubsequentSlowDecline" className="form-control grey-field">{this.getValue(advice.o2SubsequentlySlowDeclineTo.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.o2FurtherO2Decline && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseOnFurtherOxygenDecline">{this.props.language.adviseOnFurtherOxygenDecline}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseOnFurtherOxygenDecline" name="adviseOnFurtherOxygenDecline" className="form-control grey-field">{this.getValue(advice.o2FurtherO2Decline.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.dca && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseAfterLoading">{this.props.language.adviseDCA}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseDCA" name="adviseDCA" className="form-control grey-field">{this.getValue(advice.dca.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.moistureLoss &&
                                            <Row className="mt-3 mb-1">
                                                <Col className="col-12 col-md-4"></Col>
                                                <Col className="col-12 col-md-8">
                                                    <CardSubtitle className="group-header">{this.props.language.adviseOnMoistureLossTitle}</CardSubtitle>
                                                </Col>
                                            </Row>
                                        }
                                        {advice.moistureLoss && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="adviseOnMoistureLoss">{this.props.language.adviseOnMoistureLoss}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="adviseOnMoistureLoss" name="adviseOnMoistureLoss" className="form-control grey-field">{this.getValue(advice.moistureLoss.label)}</div>
                                            </Col>
                                        </Row>}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    {advice.product && <Col className="col-12" >
                                        <hr/>
                                        <CardTitle className="d-flex">{this.props.language.productTitle}</CardTitle>
                                        <hr/>
                                        {advice.product.product && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="product">{this.props.language.product}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="product" name="product" className="form-control grey-field">{this.getValue(advice.product.product.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.product.variety && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="variety">{this.props.language.variety}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="variety" name="variety" className="form-control grey-field">{this.getValue(advice.product.variety.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.product.mutant && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="mutant">{this.props.language.mutant}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="mutant" name="mutant" className="form-control grey-field">{this.getValue(advice.product.mutant.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.product.typeOfPick && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="typeOfPick">{this.props.language.typeOfPick}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="typeOfPick" name="typeOfPick" className="form-control grey-field">{this.getValue(advice.product.typeOfPick.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.product.normalOrControlledAir && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="normalOrControlledAir">{this.props.language.normalOrControlledAir}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="normalOrControlledAir" name="normalOrControlledAir" className="form-control grey-field">{this.getValue(advice.product.normalOrControlledAir.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.product.apply1Mcp && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="apply1Mcp">{this.props.language.apply1Mcp}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="apply1Mcp" name="apply1Mcp" className="form-control grey-field">{this.getValue(advice.product.apply1Mcp.label)}</div>
                                            </Col>
                                        </Row>}
                                        {advice.product.climateZone && <Row className="p-2">
                                            <Col className="col-12 col-md-4">
                                                <Label htmlFor="climateZone">{this.props.language.climateZone}</Label>
                                            </Col>
                                            <Col className="col-12 col-md-8">
                                                <div id="climateZone" name="climateZone" className="form-control grey-field">{this.getValue(advice.product.climateZone.label)}</div>
                                            </Col>
                                        </Row>}
                                    </Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </FadeTransform>
                </Container>
            );
        }
    }
}

export default AdviceComponent;