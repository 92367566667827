import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {assetsURL} from "../shared/baseUrl";
import React from "react";

export const ErrorComponent = props => {
    return (
        <Container className="pt-5">
            <div className="alert alert-danger">
                <strong>{props.error}</strong>
            </div>
        </Container>
    );
};
