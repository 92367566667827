import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner);

export const Loading = () => {
    return(
        <div className="col-12 text-center spacing-top">
            <FontAwesomeIcon icon="spinner" className="fa-pulse fa-3x fa-fw text-primary" />
            <p>Loading ...</p>
        </div>
    );
};
