import React, {Component} from 'react';
import {Container, Breadcrumb, BreadcrumbItem,
     Col, Row, CardBody, CardTitle, Card,CardText} from 'reactstrap';
import { Link } from "react-router-dom";

class DisclamierComponent extends Component{

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(values) {
        this.props.postFeedback(values.firstname, values.lastname, values.telnum, values.email, values.agree, values.contactType, values.message);
        this.props.resetFeedbackForm();
    }


    render(){
        return(
            <Container >
                <Breadcrumb className="bg-transparent">
                    <BreadcrumbItem><Link to='/home'>{this.props.language.homeMenu} </Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.props.language.disclamierTitle}</BreadcrumbItem>
                </Breadcrumb>
                <Card>
                    <CardBody>
                        <Row className="row">
                            <Col className="col-12">
                                <CardTitle className="d-flex justify-content-center">
                                    {this.props.language.disclamierTitle}
                                </CardTitle>
                                <hr/>
                                <CardText>
                                    {this.props.language.disclamier}
                                    <a href={this.props.language.termsAndConditionsURL} target="_blank"
                                       className="normal">{this.props.language.disclamierWUR}</a>
                                </CardText>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }

}

export default DisclamierComponent;