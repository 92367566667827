import React, {Component} from 'react';
import {Container,Button, FormGroup, Input, Label, Row, Col, CardBody, CardTitle, Card} from 'reactstrap';
import { Control, Form, Errors} from 'react-redux-form';
import { withRouter } from 'react-router-dom';

const required = (val) => val && val.length;
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-z]{2,4}$/i.test(val);
class LoginComponent extends Component {

    constructor(props){
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
    }

    async handleLogin(values){
        await this.props.login({email:values.email,password:values.password}).then(
            response => {
                if(response.payload.authenticated){
                    this.props.setAuthentication(true,null);
                    this.props.setEmail(response.payload.user);
                    sessionStorage.setItem("user-token",response.payload.token);
                    sessionStorage.setItem("user-email",response.payload.user);
                    this.props.fetchProductTypes();
                    this.props.resetLoginForm();
                    this.props.history.push('/product');
                }else{
                    this.props.setAuthentication(false,this.props.language.incorrectLoginMsg);
                    this.props.history.push('/home');
                }});
    }

    render() {
        return(
            <Container className="pt-5 d-flex justify-content-center">
                <Card style={{ width: '35rem' }}>
                    <CardBody>
                        <CardTitle className="d-flex justify-content-center">{this.props.language.loginTitle}</CardTitle>
                        <hr/>
                        <Row className="mt-3">
                            <Col className="col-12">
                                {this.props.isAuth &&
                                <div>
                                    <Row className="form-group">
                                        <Label htmlFor="email" md={4}>{this.props.language.email}</Label>
                                        <Col md={8}>
                                            <Control.text model=".email" id="email" name="email" placeholder=""
                                                          className="form-control"
                                                          readOnly={true}
                                                          value={this.props.email}
                                                         />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={12}>
                                            <Button className="form-control background-green" type="submit" onClick={() =>{
                                                this.props.setEmail(null);
                                                this.props.setAuthentication(false,null)
                                                this.props.logout()}}>{this.props.language.logout}</Button>
                                        </Col>
                                    </Row>
                                </div>

                                }
                                {!this.props.isAuth && <Form model="login" onSubmit={(values) => this.handleLogin(values)}>
                                    <Row className="form-group">
                                        <Label htmlFor="email" md={4}>{this.props.language.email}</Label>
                                        <Col md={8}>
                                            <Control.text model=".email" id="email" name="email" placeholder=""
                                                          className="form-control"
                                                          validators={{
                                                              required, validEmail
                                                          }}/>
                                            <Errors className="text-danger"
                                                    model=".email"
                                                    show="touched"
                                                    messages={{
                                                        required: this.props.language.required,
                                                        validEmail: this.props.language.invalidEmail,
                                                    }}/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Label htmlFor="password" md={4}>{this.props.language.password}</Label>
                                        <Col md={8}>
                                            <Control type="password" model=".password" id="password" name="password" placeholder=""
                                                          className="form-control"
                                                          validators={{
                                                              required
                                                          }}/>
                                            <Errors className="text-danger"
                                                    model=".password"
                                                    show="touched"
                                                    messages={{
                                                        required: this.props.language.required
                                                    }}/>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={12} className="d-flex justify-content-center">
                                            <Label check>
                                                <span>{this.props.language.noLoginBeforeLink} </span>
                                                <a href="https://www.wur.nl/bewaaradvies-tool"
                                                   target="_blank"
                                                   className="normal">
                                                    <span>{this.props.language.noLoginLink}</span>
                                                </a>
                                                <span> {this.props.language.noLoginAfterLink}</span>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={12}>
                                            <Button className="form-control background-green" type="submit" value="submit">{this.props.language.login}</Button>
                                        </Col>
                                    </Row>
                                </Form>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <small className="text-danger">{this.props.authenticationFailure}</small>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}
export default withRouter(LoginComponent);